const images = {
  logo: require('./logoVff.svg').default,
  facebook: require('./facebook.svg').default,
  twitter: require('./twitter.svg').default,
  arrowIcon: require('./vector.svg').default,
  iconClose: require('./iconClose.svg').default,
  hambuger: require('./hambuger.svg').default,

  logoFull: require('./logoFull.svg').default,
  circle: require('./circle.png'),
}

export default images
